import React from "react";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";

import Root from "./routes/root";
import Levels from "./routes/Levels";
import { loader as rootLoader } from "./routes/root";
import { loader as noFoundLoader } from "./routes/NoFound";
import NoFound from "./routes/NoFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: rootLoader,
    children: [
      {
        path: "/:level?",
        element: <Levels />,
      },
    ],
  },
  {
    path: "*",
    element: <NoFound />,
    loader: noFoundLoader,
  },
]);

const helmetContext = {};

const app = (
  <HelmetProvider context={helmetContext}>
    <RouterProvider router={router} />
  </HelmetProvider>
);

const root = createRoot(document.getElementById("root")!);
root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
