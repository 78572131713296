import { NavLink, useLocation } from "react-router-dom";

const levelLinks = [
  { to: "/easy", label: "Easy" },
  { to: "/normal", label: "Normal" },
  { to: "/hard", label: "Hard" },
  { to: "/killer", label: "Killer" },
];

export default function NavbarLink() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="flex justify-between w-[200px]">
      {levelLinks.map(({ to, label }) => (
        <NavLink
          key={to}
          to={to}
          className={({ isActive }) => {
            if (to === "/normal" && isHomePage) {
              return "navLink";
            } else {
              return (!isActive ? "navLink-inactive" : "") + " navLink";
            }
          }}
          defaultValue="/normal"
          reloadDocument={true}
        >
          {label}
        </NavLink>
      ))}
    </div>
  );
}
