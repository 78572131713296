import { GameStats } from "../../lib/localStorage";
import { Progress } from "./Progress";

type Props = {
  gameStats: GameStats;
  guesses: string[][];
  isGameWon: boolean;
};

export const Histogram = ({ gameStats, guesses, isGameWon }: Props) => {
  const winDistribution = gameStats.winDistribution;
  const maxValue = Math.max(...winDistribution);
  const indexToHighlight = guesses.length - 1;

  return (
    <div className="columns-1 justify-left m-2 text-sm">
      {winDistribution.map((value, i) => (
        <Progress
          key={i}
          index={i}
          size={90 * (value / maxValue)}
          label={String(value)}
          highlight={isGameWon && i === indexToHighlight}
        />
      ))}
    </div>
  );
};
