export const CONFIG = {
  tries: 6, // This changes how many tries you get to finish the wordle
  language: "YourLanguageHere", // This changes the display name for your language
  author: "YourNameHere", // Put your name here so people know who made this Wordle!
  authorWebsite: "YourLinkHere", // Put a link to your website or social media here
  wordListSource: "YourDictionarySource", // Describe the source material for your words here
  wordListSourceLink: "YourDictionaryLink", // Put a link to the source material for your words here
  //
  // THESE NEXT SETTINGS ARE FOR ADVANCED USERS
  //
  googleAnalytics: "G-6T72RZVEKT", // You can use this if you use Google Analytics
  shuffle: false, // whether to shuffle the words in the wordlist each time you load the app (note: you will lose the 'word of the day' functionality if this is true)
  normalization: "NFC", // whether to apply Unicode normalization to words and orthography - options: 'NFC', 'NFD', 'NKFC', 'NKFD', false
};
