import { getGuessStatuses } from "../../lib/statuses";
import { Level } from "../../utils/isValidLevel";
import { Cell } from "./Cell";

type Props = {
  guess: string[];
  solution: string;
  level: Level;
};

export const CompletedRow = ({ guess, solution, level }: Props) => {
  const statuses = getGuessStatuses(guess, solution);

  return (
    <div className="flex justify-center mb-1">
      {guess.map((letter, i) => (
        <Cell key={i} value={letter} status={statuses[i]} level={level} />
      ))}
    </div>
  );
};
