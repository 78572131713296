import { Level } from "../../utils/isValidLevel";

export default function RestartPracticeGame({
  level,
  isPracticeGame,
  isGameWon,
  isGameLost,
}: {
  level: Level;
  isPracticeGame: boolean;
  isGameWon: boolean;
  isGameLost: boolean;
}) {
  if (!isPracticeGame || isGameLost || isGameWon) return <></>;

  return (
    <button
      className="text-center font-sans font-nunito font-bold text-lg leading-snug underline my-5"
      type="button"
      onClick={() => {
        localStorage.removeItem(`practiceLevel-${level}`);
        window.location.reload();
      }}
    >
      Restart Practice Game
    </button>
  );
}
