import { Level } from "../../utils/isValidLevel";
import PracticeButton from "./PracticeButton";
import ShareButton from "./ShareButton";

export default function GameCompletionAction({
  isGameWon,
  isPracticeGame,
  level,
  isGameLost,
  guesses,
  solution,
  solutionIndex,
  handleShare,
}: {
  isGameWon: boolean;
  isPracticeGame: boolean;
  level: Level;
  isGameLost: boolean;
  guesses: string[][];
  solution: string;
  solutionIndex: number;
  handleShare: () => void;
}) {
  if (!isGameWon && !isGameLost) return <></>;

  return (
    <div className="flex flex-col justify-center items-center w-80 my-5 mx-auto">
      <h2 className="font-extrabold text-[20px] leading-9">
        {isGameWon ? "Hey, Good Game!" : "Almost had it!"}
      </h2>
      {isPracticeGame ? (
        <PracticeButton
          level={level}
          isPractice={isPracticeGame}
          isGameLost={isGameLost}
        />
      ) : (
        <ShareButton
          guesses={guesses}
          isPracticeGame={isPracticeGame}
          isGameLost={isGameLost}
          isGameWon={isGameWon}
          solution={solution}
          solutionIndex={solutionIndex}
          handleShare={handleShare}
          level={level}
        />
      )}
    </div>
  );
}
