import { useEffect } from "react";
import ReactGA from "react-ga4";

import { CONFIG } from "../constants/config";
import { useLocation } from "react-router-dom";

function initializeGoogleAnalytics() {
  const TRACKING_ID = CONFIG.googleAnalytics;
  ReactGA.initialize(TRACKING_ID, {
    gaOptions: {
      single_or_multi_domain_version: "single",
    },
  });
}

function handleURLChange() {
  const page = window.location.pathname + window.location.search;

  console.log("Calling Google Analytics pageview event");
  ReactGA.send({
    hitType: "pageview",
    page: page,
    single_or_multi_domain_version: "single",
  });
}

export default function useGoogleAnalyticsPageTracking() {
  const location = useLocation();

  useEffect(() => {
    if (!ReactGA.isInitialized) {
      initializeGoogleAnalytics();
    }

    handleURLChange();
  }, [location]);
}

// This function emulates the behavior of a previous implementation
// where every guess triggered a page reload, sending a pageview event
// to Google Analytics. While this is not recommended, it reflects the
// old state.
export function collectGoogleAnalyticsPageView() {
  if (!ReactGA.isInitialized) {
    return;
  }

  handleURLChange();
}
