import { Link } from "react-router-dom";
import useDarkMode from "../../hooks/useDarkMode";

export default function MathlerTitle() {
  const isDarkMode = useDarkMode();
  let title = <p>Mathler</p>;

  if (!isDarkMode) {
    title = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="133"
        height="28"
        viewBox="0 0 133 28"
        fill="none"
      >
        <path
          d="M118.69 11.3399L118.12 7.23593H124.238L124.846 9.85793C125.948 7.27393 127.848 6.62793 129.178 6.62793C130.166 6.62793 131.42 6.81793 132.332 7.34993L131.382 13.7719C130.546 13.3919 129.596 13.0879 128.608 13.0879C127.278 13.0879 126.366 13.6199 126.366 15.1019V26.8819H118.69V11.3399Z"
          fill="#1B0E0B"
        />
        <path
          d="M108.948 27.4899C101.994 27.4899 99.2958 23.4619 99.2958 17.6859C99.2958 12.1759 102.64 6.62793 109.442 6.62793C113.584 6.62793 116.51 8.60393 116.51 13.0499C116.51 18.2559 111.038 19.6239 107.124 20.0039C107.428 21.1439 108.492 21.8659 110.126 21.8659C111.684 21.8659 113.356 21.2199 114.762 20.3079L116.7 25.3239C116.776 25.2859 113.66 27.4899 108.948 27.4899ZM106.744 15.4819C108.796 15.2919 109.974 14.0759 109.974 12.9739C109.974 12.3659 109.594 11.9479 108.986 11.9479C107.922 11.9479 106.858 12.9739 106.744 15.4819Z"
          fill="#1B0E0B"
        />
        <path
          d="M93.4639 27.4899C87.0039 27.4899 86.5859 23.3099 86.5859 19.4719V0.471924H94.2619V19.5479C94.2619 20.8019 94.6799 21.5239 95.5919 21.5239C96.0859 21.5239 96.7699 21.2959 97.7199 20.9159L99.3159 25.9319C97.7579 27.0719 95.2119 27.4899 93.4639 27.4899Z"
          fill="#1B0E0B"
        />
        <path
          d="M66.0645 0.471924H73.7405V7.53993C74.6145 7.04593 75.8685 6.62792 77.4265 6.62792C81.4545 6.62792 83.6585 8.86992 83.6585 13.5819V26.8819H75.9825V14.4179C75.9825 13.0879 75.7545 12.4799 75.0705 12.4799C74.1205 12.4799 73.7405 13.1639 73.7405 14.3799V26.8819H66.0645V0.471924Z"
          fill="#1B0E0B"
        />
        <path
          d="M58.7041 27.4899C51.8261 27.4899 51.4081 23.3859 51.4081 19.6239V0.471924H59.1221V7.23592H63.6441V13.1639H59.1221V19.2439C59.1221 21.1439 60.1101 21.7519 61.2121 21.7519C61.7441 21.7519 62.3521 21.5619 62.9601 21.2199L64.5561 26.0839C63.3401 26.8439 61.0601 27.4899 58.7041 27.4899Z"
          fill="#1B0E0B"
        />
        <path
          d="M37.2379 27.5279C31.3099 27.5279 29.7899 22.8159 29.7899 17.9139C29.7899 11.2639 33.3999 6.62793 40.3919 6.62793C41.7979 6.62793 44.8759 6.96993 48.1439 8.26193V22.4739L48.6379 26.8819H42.2159L41.8739 25.2859L41.6459 25.2479C40.6579 26.7299 39.1759 27.5279 37.2379 27.5279ZM39.2519 22.0559C40.0499 22.0559 40.5819 21.4099 40.5819 20.7259V12.0619C40.2399 11.9479 39.9359 11.9099 39.6319 11.9099C37.9219 11.9099 37.3519 14.6079 37.3519 17.2679C37.3519 20.0419 37.7319 22.0559 39.2519 22.0559Z"
          fill="#1B0E0B"
        />
        <path
          d="M2.11197 1.61182H10.092L14.31 14.0758H14.5L18.87 1.61182H26.774L28.066 26.8818H20.58L20.01 16.7358H19.82L17.084 24.5258H11.688L8.87597 16.9638H8.72397L8.11597 26.8818H0.667969L2.11197 1.61182Z"
          fill="#1B0E0B"
        />
      </svg>
    );
  } else {
    title = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="133"
        height="28"
        viewBox="0 0 133 28"
        fill="none"
      >
        <path
          d="M118.69 11.3399L118.12 7.23593H124.238L124.846 9.85793C125.948 7.27393 127.848 6.62793 129.178 6.62793C130.166 6.62793 131.42 6.81793 132.332 7.34993L131.382 13.7719C130.546 13.3919 129.596 13.0879 128.608 13.0879C127.278 13.0879 126.366 13.6199 126.366 15.1019V26.8819H118.69V11.3399Z"
          fill="#F7F3F2"
        />
        <path
          d="M108.948 27.4899C101.994 27.4899 99.2959 23.4619 99.2959 17.6859C99.2959 12.1759 102.64 6.62793 109.442 6.62793C113.584 6.62793 116.51 8.60393 116.51 13.0499C116.51 18.2559 111.038 19.6239 107.124 20.0039C107.428 21.1439 108.492 21.8659 110.126 21.8659C111.684 21.8659 113.356 21.2199 114.762 20.3079L116.7 25.3239C116.776 25.2859 113.66 27.4899 108.948 27.4899ZM106.744 15.4819C108.796 15.2919 109.974 14.0759 109.974 12.9739C109.974 12.3659 109.594 11.9479 108.986 11.9479C107.922 11.9479 106.858 12.9739 106.744 15.4819Z"
          fill="#F7F3F2"
        />
        <path
          d="M93.4639 27.4899C87.0039 27.4899 86.5859 23.3099 86.5859 19.4719V0.471924H94.2619V19.5479C94.2619 20.8019 94.6799 21.5239 95.5919 21.5239C96.0859 21.5239 96.7699 21.2959 97.7199 20.9159L99.3159 25.9319C97.7579 27.0719 95.2119 27.4899 93.4639 27.4899Z"
          fill="#F7F3F2"
        />
        <path
          d="M66.0645 0.471924H73.7405V7.53993C74.6145 7.04593 75.8685 6.62792 77.4265 6.62792C81.4545 6.62792 83.6585 8.86992 83.6585 13.5819V26.8819H75.9825V14.4179C75.9825 13.0879 75.7545 12.4799 75.0705 12.4799C74.1205 12.4799 73.7405 13.1639 73.7405 14.3799V26.8819H66.0645V0.471924Z"
          fill="#F7F3F2"
        />
        <path
          d="M58.704 27.4899C51.826 27.4899 51.408 23.3859 51.408 19.6239V0.471924H59.122V7.23592H63.644V13.1639H59.122V19.2439C59.122 21.1439 60.11 21.7519 61.212 21.7519C61.744 21.7519 62.352 21.5619 62.96 21.2199L64.556 26.0839C63.34 26.8439 61.06 27.4899 58.704 27.4899Z"
          fill="#F7F3F2"
        />
        <path
          d="M37.238 27.5279C31.31 27.5279 29.79 22.8159 29.79 17.9139C29.79 11.2639 33.4 6.62793 40.392 6.62793C41.798 6.62793 44.876 6.96993 48.144 8.26193V22.4739L48.638 26.8819H42.216L41.874 25.2859L41.646 25.2479C40.658 26.7299 39.176 27.5279 37.238 27.5279ZM39.252 22.0559C40.05 22.0559 40.582 21.4099 40.582 20.7259V12.0619C40.24 11.9479 39.936 11.9099 39.632 11.9099C37.922 11.9099 37.352 14.6079 37.352 17.2679C37.352 20.0419 37.732 22.0559 39.252 22.0559Z"
          fill="#F7F3F2"
        />
        <path
          d="M2.11197 1.61182H10.092L14.31 14.0758H14.5L18.87 1.61182H26.774L28.066 26.8818H20.58L20.01 16.7358H19.82L17.084 24.5258H11.688L8.87597 16.9638H8.72397L8.11597 26.8818H0.667969L2.11197 1.61182Z"
          fill="#F7F3F2"
        />
      </svg>
    );
  }

  return <Link to="/">{title}</Link>;
}
