import { Cell } from "../grid/Cell";
import { BaseModal } from "./BaseModal";
import { Level } from "../../utils/isValidLevel";
import { useState } from "react";
import capitalize from "../../utils/capitalize";
import { useEffectAfterMigration } from "../../hooks/useEffectAfterMigration";
import { updateKey } from "./NewUpdateModal";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean | ((prevState: boolean) => boolean)) => void;
  level: Level;
};

const levels = ["easy", "normal", "hard", "killer"];

export const InfoModal = ({ isOpen, setIsOpen, level }: Props) => {
  const [currentLevel, setCurrentLevel] = useState(level);
  const [key, setKey] = useState("playedbefore");
  const keyValue = "played";

  useEffectAfterMigration(() => {
    setKey(level === "normal" ? "playedbefore" : `playedbefore-${level}`);
  }, [level]);

  useEffectAfterMigration(() => {
    const playedbefore = localStorage.getItem(key);
    const shouldOpen = level !== "normal" && playedbefore !== keyValue;
    if (shouldOpen) {
      setIsOpen(true);
      setIsOpen(true);
      localStorage.setItem(updateKey, "true"); // We do not need to see the updated modal
      localStorage.setItem(key, keyValue);
    }
  }, [level, setIsOpen, key]);

  return (
    <BaseModal
      title={`How to Play ${capitalize(currentLevel)} Mathler`}
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
    >
      <div className="flex justify-between w-[200px]">
        {levels.map((l) => (
          <button
            key={l}
            className={`navLink ${
              currentLevel !== l.toLowerCase() ? "navLink-inactive" : ""
            }`}
            onClick={() => {
              setCurrentLevel(l.toLowerCase() as Level);
              !localStorage.getItem(key) && localStorage.setItem(key, keyValue);
            }}
          >
            {capitalize(l)}
          </button>
        ))}
      </div>

      <div className="border-b border-[#D3DDEB] my-4"></div>
      <div className="flex flex-col text-base font-normal gap-y-4">
        {currentLevel === "easy" ? (
          <EasyContent />
        ) : currentLevel === "normal" ? (
          <NormalContent />
        ) : currentLevel === "hard" ? (
          <HardContent />
        ) : (
          <KillerContent />
        )}
      </div>
    </BaseModal>
  );
};

function LevelDifference({
  level,
  difference,
}: {
  level: string;
  difference: string;
}) {
  return (
    <div className="text-black items-start p-2 rounded-md bg-[#D3DDEB]">
      <b>{level} Mathler is different</b> because {difference}
    </div>
  );
}

function EasyContent() {
  return (
    <>
      <LevelDifference
        level="Easy"
        difference="you have 5 numbers and 1 operator."
      />
      <p>
        Find the hidden calculation in 6 guesses. After each guess, the colors
        will show how close you were.
      </p>

      <div className="flex justify-center">
        <Cell value="5" status="correct" />
        <Cell value="0" status="absent" />
        <Cell value="-" status="correct" />
        <Cell value="2" status="present" />
        <Cell value="5" status="absent" />
      </div>

      <ul className="list-disc pl-4">
        <li>The first 5 and - are in the correct place.</li>
        <li>The second 5 is in the solution in another place.</li>
        <li>0 and 2 are not in the solution.</li>
      </ul>

      <p className="text-xl font-bold">Additional Rules</p>
      <ul className="list-disc pl-4">
        <li>
          Commutative solutions are accepted, for example 20+12 and 12+20.
        </li>
        <li>
          Commutative solutions will be automatically rearranged to the exact
          solution.
        </li>
      </ul>
    </>
  );
}

function NormalContent() {
  return (
    <>
      <p>Try to find the hidden calculation in 6 guesses!</p>
      <p>
        After each guess, the color of the tiles will change to show how close
        you are to the solution.
      </p>

      <div className="flex justify-center">
        <Cell value="5" status="correct" />
        <Cell value="0" status="absent" />
        <Cell value="/" status="correct" />
        <Cell value="5" status="present" />
        <Cell value="-" status="absent" />
        <Cell value="2" status="absent" />
      </div>

      <ul className="list-disc pl-4">
        <li>Green are in the correct place.</li>
        <li>Orange are in the solution, but in a different place.</li>
        <li>Gray are not in the solution.</li>
      </ul>

      <p className="text-xl font-bold">Additional Rules</p>
      <ul className="list-disc pl-4">
        <li>Numbers and operators can appear multiple times.</li>
        <li>Calculate / or * before - or + (order of operations).</li>
        <li>
          Commutative solutions are accepted, for example 20+7+3 and 3+7+20.
        </li>
        <li>
          Commutative solutions will be automatically rearranged to the exact
          solution.
        </li>
      </ul>
    </>
  );
}

function HardContent() {
  return (
    <>
      <LevelDifference
        level="Hard"
        difference="there are 8 squares and can include parenthesis."
      />

      <p>
        Find the hidden calculation in 6 guesses. After each guess, the colors
        will show how close you were.
      </p>

      <div className="flex justify-center">
        <Cell value="(" status="correct" />
        <Cell value="9" status="absent" />
        <Cell value="-" status="absent" />
        <Cell value="5" status="correct" />
        <Cell value=")" status="correct" />
        <Cell value="*" status="absent" />
        <Cell value="1" status="present" />
        <Cell value="5" status="present" />
      </div>

      <ul className="list-disc pl-4">
        <li>The (, first 5, and ) are in the correct place.</li>
        <li>
          1 and the second 5 are in the solution, but in a different place.
        </li>
        <li>9, - and * are not in the solution.</li>
      </ul>

      <p className="text-xl font-bold">Additional Rules</p>
      <ul className="list-disc pl-4">
        <li>Numbers and operators can appear multiple times.</li>
        <li>Calculate / or * before - or + (order of operations).</li>
        <li>
          Commutative solutions are accepted, for example (20+6)/4 and (6+20)/4.
        </li>
        <li>
          Commutative solutions will be automatically rearranged to the exact
          solution.
        </li>
      </ul>
    </>
  );
}

function KillerContent() {
  return (
    <>
      <LevelDifference
        level="Killer"
        difference="it only ever shows you exact matches. There are no yellow hints."
      />

      <p>Try to find the hidden calculation in 6 guesses!</p>
      <p>
        After each guess the color of the tiles will ONLY change if they are in
        the correct place.
      </p>

      <div className="flex justify-center">
        <Cell value="5" status="correct" />
        <Cell value="0" status="absent" />
        <Cell value="/" status="correct" />
        <Cell value="5" status="absent" />
        <Cell value="-" status="absent" />
        <Cell value="2" status="absent" />
      </div>

      <ul className="list-disc pl-4">
        <li>Green are in the correct place.</li>
        <li>Gray are not in the solution.</li>
      </ul>

      <p className="text-xl font-bold">Additional Rules</p>
      <ul className="list-disc pl-4">
        <li>Numbers and operators can appear multiple times.</li>
        <li>Calculate / or * before - or + (order of operations).</li>
        <li>
          Commutative solutions are accepted, for example (20+6)/4 and (6+20)/4.
        </li>
        <li>
          Commutative solutions will be automatically rearranged to the exact
          solution.
        </li>
      </ul>
    </>
  );
}
