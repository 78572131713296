import useDarkMode from "../../hooks/useDarkMode";

export default function MathlerIcon() {
  const isDarkMode = useDarkMode();

  if (isDarkMode) {
    return (
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_5890_3182)">
          <rect x="3.66797" y="3" width="24" height="24" fill="white" />
          <rect x="3.16797" y="2.5" width="25" height="25" stroke="#150C09" />
        </g>
        <rect x="3.66797" y="19" width="8" height="8" fill="#FCEB4E" />
        <rect x="19.668" y="11" width="8" height="8" fill="#FCEB4E" />
        <rect x="3.66797" y="3" width="8" height="8" fill="#E3DCDA" />
        <rect x="11.668" y="3" width="8" height="8" fill="#91D67D" />
        <rect x="19.668" y="19" width="8" height="8" fill="#91D67D" />
        <rect x="11.668" y="11" width="8" height="8" fill="#E3DCDA" />
        <path d="M11.668 3V27" stroke="black" />
        <path d="M19.668 3L19.668 27" stroke="black" />
        <path d="M3.66797 11H27.668" stroke="black" />
        <path d="M3.66797 19H27.668" stroke="black" />
        <path
          d="M7.63787 9.05966C7.29412 9.05966 6.98873 9.00071 6.72168 8.88281C6.45605 8.76349 6.24725 8.59943 6.09526 8.39062C5.94327 8.18182 5.86657 7.94176 5.86515 7.67045H7.05833C7.05975 7.75426 7.08532 7.82955 7.13503 7.89631C7.18617 7.96165 7.25577 8.01278 7.34384 8.04972C7.43191 8.08665 7.53276 8.10511 7.6464 8.10511C7.75577 8.10511 7.85236 8.08594 7.93617 8.04759C8.01998 8.00781 8.08532 7.95312 8.13219 7.88352C8.17907 7.81392 8.20179 7.73437 8.20037 7.64489C8.20179 7.55682 8.1748 7.47869 8.11941 7.41051C8.06543 7.34233 7.98944 7.28906 7.89142 7.25071C7.79341 7.21236 7.68049 7.19318 7.55265 7.19318H7.10946V6.375H7.55265C7.67054 6.375 7.77424 6.35582 7.86373 6.31747C7.95463 6.27912 8.02495 6.22585 8.07466 6.15767C8.1258 6.08949 8.15066 6.01136 8.14924 5.9233C8.15066 5.83807 8.13006 5.76278 8.08745 5.69744C8.04483 5.6321 7.98517 5.58097 7.90847 5.54403C7.83319 5.5071 7.74583 5.48864 7.6464 5.48864C7.53844 5.48864 7.44185 5.50781 7.35662 5.54616C7.27282 5.58452 7.20676 5.63778 7.15847 5.70597C7.11017 5.77415 7.08532 5.85227 7.0839 5.94034H5.95037C5.95179 5.6733 6.02495 5.4375 6.16983 5.23295C6.31614 5.02841 6.51642 4.8679 6.77069 4.75142C7.02495 4.63494 7.31685 4.5767 7.6464 4.5767C7.96884 4.5767 8.25364 4.63139 8.5008 4.74077C8.74938 4.85014 8.94327 5.00071 9.08248 5.19247C9.2231 5.38281 9.2927 5.60085 9.29128 5.84659C9.29412 6.09091 9.21032 6.29119 9.03986 6.44744C8.87083 6.60369 8.65634 6.69602 8.3964 6.72443V6.75852C8.74867 6.79687 9.01358 6.90483 9.19114 7.08239C9.3687 7.25852 9.45605 7.48011 9.45321 7.74716C9.45463 8.00284 9.37793 8.2294 9.2231 8.42685C9.06969 8.62429 8.85591 8.77912 8.58176 8.89134C8.30904 9.00355 7.99441 9.05966 7.63787 9.05966Z"
          fill="black"
        />
        <path
          d="M8.57937 12.6364V17H7.39471V13.7273H7.36914L6.4146 14.2983V13.2926L7.48846 12.6364H8.57937Z"
          fill="black"
        />
        <path
          d="M7.72789 25.0597C7.47363 25.0597 7.23216 25.0199 7.00346 24.9403C6.77619 24.8594 6.57449 24.7322 6.39835 24.5589C6.22221 24.3857 6.08372 24.1598 5.98287 23.8814C5.88343 23.603 5.83443 23.2656 5.83585 22.8693C5.83727 22.5156 5.88272 22.1974 5.97221 21.9148C6.06312 21.6321 6.19167 21.3913 6.35787 21.1925C6.52548 20.9936 6.72576 20.8416 6.95872 20.7365C7.19167 20.63 7.45091 20.5767 7.73642 20.5767C8.05744 20.5767 8.34011 20.6385 8.58443 20.7621C8.82875 20.8857 9.02335 21.0511 9.16824 21.2585C9.31454 21.4645 9.39977 21.6918 9.42392 21.9403H8.2563C8.22931 21.8168 8.16681 21.7266 8.0688 21.6697C7.97221 21.6115 7.86142 21.5824 7.73642 21.5824C7.48784 21.5824 7.30531 21.6903 7.18883 21.9062C7.07377 22.1222 7.01483 22.4062 7.01199 22.7585H7.03755C7.09295 22.625 7.17676 22.5107 7.28897 22.4155C7.40119 22.3203 7.53187 22.2472 7.68102 22.196C7.83017 22.1449 7.98784 22.1193 8.15403 22.1193C8.41824 22.1193 8.64906 22.179 8.8465 22.2983C9.04537 22.4176 9.2002 22.581 9.31099 22.7884C9.42179 22.9957 9.47647 23.233 9.47505 23.5C9.47647 23.8125 9.40261 24.0859 9.25346 24.3203C9.10574 24.5547 8.90048 24.7365 8.6377 24.8658C8.37491 24.995 8.07164 25.0597 7.72789 25.0597ZM7.71937 24.1648C7.83727 24.1648 7.94167 24.1378 8.03258 24.0838C8.12491 24.0298 8.19664 23.956 8.24778 23.8622C8.30034 23.7685 8.32591 23.6619 8.32449 23.5426C8.32591 23.4219 8.30034 23.3153 8.24778 23.223C8.19664 23.1293 8.12491 23.0554 8.03258 23.0014C7.94167 22.9474 7.83727 22.9205 7.71937 22.9205C7.63272 22.9205 7.55247 22.9361 7.4786 22.9673C7.40616 22.9972 7.34295 23.0405 7.28897 23.0973C7.235 23.1527 7.19238 23.2187 7.16113 23.2955C7.1313 23.3707 7.11568 23.4531 7.11426 23.5426C7.11568 23.6619 7.14267 23.7685 7.19522 23.8622C7.24778 23.956 7.31951 24.0298 7.41042 24.0838C7.50133 24.1378 7.60431 24.1648 7.71937 24.1648Z"
          fill="black"
        />
        <path
          d="M22.0183 17V16.1477L23.6461 14.8011C23.7541 14.7116 23.8464 14.6278 23.9231 14.5497C24.0012 14.4702 24.0609 14.3885 24.1021 14.3047C24.1447 14.2209 24.166 14.1278 24.166 14.0256C24.166 13.9134 24.1419 13.8175 24.0936 13.7379C24.0467 13.6584 23.9814 13.5973 23.8975 13.5547C23.8137 13.5107 23.7172 13.4886 23.6078 13.4886C23.4984 13.4886 23.4018 13.5107 23.318 13.5547C23.2356 13.5987 23.1717 13.6634 23.1262 13.7486C23.0808 13.8338 23.0581 13.9375 23.0581 14.0597H21.9331C21.9331 13.7528 22.002 13.4886 22.1397 13.267C22.2775 13.0455 22.4721 12.875 22.7235 12.7557C22.975 12.6364 23.2697 12.5767 23.6078 12.5767C23.9572 12.5767 24.2598 12.6328 24.5154 12.745C24.7725 12.8558 24.9707 13.0121 25.1099 13.2138C25.2505 13.4155 25.3208 13.652 25.3208 13.9233C25.3208 14.0909 25.286 14.2578 25.2164 14.424C25.1468 14.5888 25.0218 14.7713 24.8414 14.9716C24.661 15.1719 24.4047 15.4105 24.0723 15.6875L23.6632 16.0284V16.054H25.3677V17H22.0183Z"
          fill="black"
        />
        <path
          d="M23.6379 25.0597C23.2941 25.0597 22.9887 25.0007 22.7217 24.8828C22.4561 24.7635 22.2472 24.5994 22.0953 24.3906C21.9433 24.1818 21.8666 23.9418 21.8651 23.6705H23.0583C23.0597 23.7543 23.0853 23.8295 23.135 23.8963C23.1862 23.9616 23.2558 24.0128 23.3438 24.0497C23.4319 24.0866 23.5328 24.1051 23.6464 24.1051C23.7558 24.1051 23.8524 24.0859 23.9362 24.0476C24.02 24.0078 24.0853 23.9531 24.1322 23.8835C24.1791 23.8139 24.2018 23.7344 24.2004 23.6449C24.2018 23.5568 24.1748 23.4787 24.1194 23.4105C24.0654 23.3423 23.9894 23.2891 23.8914 23.2507C23.7934 23.2124 23.6805 23.1932 23.5526 23.1932H23.1095V22.375H23.5526C23.6705 22.375 23.7742 22.3558 23.8637 22.3175C23.9546 22.2791 24.0249 22.2259 24.0747 22.1577C24.1258 22.0895 24.1507 22.0114 24.1492 21.9233C24.1507 21.8381 24.1301 21.7628 24.0874 21.6974C24.0448 21.6321 23.9852 21.581 23.9085 21.544C23.8332 21.5071 23.7458 21.4886 23.6464 21.4886C23.5384 21.4886 23.4419 21.5078 23.3566 21.5462C23.2728 21.5845 23.2068 21.6378 23.1585 21.706C23.1102 21.7741 23.0853 21.8523 23.0839 21.9403H21.9504C21.9518 21.6733 22.0249 21.4375 22.1698 21.233C22.3161 21.0284 22.5164 20.8679 22.7707 20.7514C23.0249 20.6349 23.3169 20.5767 23.6464 20.5767C23.9688 20.5767 24.2536 20.6314 24.5008 20.7408C24.7494 20.8501 24.9433 21.0007 25.0825 21.1925C25.2231 21.3828 25.2927 21.6009 25.2913 21.8466C25.2941 22.0909 25.2103 22.2912 25.0399 22.4474C24.8708 22.6037 24.6563 22.696 24.3964 22.7244V22.7585C24.7487 22.7969 25.0136 22.9048 25.1911 23.0824C25.3687 23.2585 25.4561 23.4801 25.4532 23.7472C25.4546 24.0028 25.3779 24.2294 25.2231 24.4268C25.0697 24.6243 24.8559 24.7791 24.5818 24.8913C24.309 25.0036 23.9944 25.0597 23.6379 25.0597Z"
          fill="black"
        />
        <path
          d="M15.3257 8.09091L15.3939 7.34091L14.7718 7.77557L14.4308 7.17898L15.1127 6.86364L14.4308 6.5483L14.7718 5.9517L15.3939 6.38636L15.3257 5.63636H16.0075L15.9394 6.38636L16.5615 5.9517L16.9024 6.5483L16.2206 6.86364L16.9024 7.17898L16.5615 7.77557L15.9394 7.34091L16.0075 8.09091H15.3257Z"
          fill="black"
        />
        <path
          d="M15.1902 16.804V13.6506H16.1447V16.804H15.1902ZM14.0907 15.7045V14.75H17.2441V15.7045H14.0907Z"
          fill="black"
        />
        <path
          d="M16.8691 20.4318L15.4629 25.6562H14.4657L15.872 20.4318H16.8691Z"
          fill="black"
        />
        <path
          d="M24.5794 4.63636V9H23.3947V5.72727H23.3691L22.4146 6.2983V5.29261L23.4885 4.63636H24.5794Z"
          fill="black"
        />
        <defs>
          <filter
            id="filter0_d_5890_3182"
            x="2.66797"
            y="2"
            width="28"
            height="28"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.239003 0 0 0 0 0.247328 0 0 0 0 0.260856 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5890_3182"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5890_3182"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_5102_2930)">
          <rect x="3.66797" y="3" width="24" height="24" fill="white" />
          <rect x="3.16797" y="2.5" width="25" height="25" stroke="#150C09" />
        </g>
        <rect x="3.66797" y="19" width="8" height="8" fill="#FCEB4E" />
        <rect x="19.668" y="11" width="8" height="8" fill="#FCEB4E" />
        <rect x="3.66797" y="3" width="8" height="8" fill="#E3DCDA" />
        <rect x="11.668" y="3" width="8" height="8" fill="#91D67D" />
        <rect x="19.668" y="19" width="8" height="8" fill="#91D67D" />
        <rect x="11.668" y="11" width="8" height="8" fill="#E3DCDA" />
        <path d="M11.668 3V27" stroke="black" />
        <path d="M19.668 3L19.668 27" stroke="black" />
        <path d="M3.66797 11H27.668" stroke="black" />
        <path d="M3.66797 19H27.668" stroke="black" />
        <path
          d="M7.63787 9.05966C7.29412 9.05966 6.98873 9.00071 6.72168 8.88281C6.45605 8.76349 6.24725 8.59943 6.09526 8.39062C5.94327 8.18182 5.86657 7.94176 5.86515 7.67045H7.05833C7.05975 7.75426 7.08532 7.82955 7.13503 7.89631C7.18617 7.96165 7.25577 8.01278 7.34384 8.04972C7.43191 8.08665 7.53276 8.10511 7.6464 8.10511C7.75577 8.10511 7.85236 8.08594 7.93617 8.04759C8.01998 8.00781 8.08532 7.95312 8.13219 7.88352C8.17907 7.81392 8.20179 7.73437 8.20037 7.64489C8.20179 7.55682 8.1748 7.47869 8.11941 7.41051C8.06543 7.34233 7.98944 7.28906 7.89142 7.25071C7.79341 7.21236 7.68049 7.19318 7.55265 7.19318H7.10946V6.375H7.55265C7.67054 6.375 7.77424 6.35582 7.86373 6.31747C7.95463 6.27912 8.02495 6.22585 8.07466 6.15767C8.1258 6.08949 8.15066 6.01136 8.14924 5.9233C8.15066 5.83807 8.13006 5.76278 8.08745 5.69744C8.04483 5.6321 7.98517 5.58097 7.90847 5.54403C7.83319 5.5071 7.74583 5.48864 7.6464 5.48864C7.53844 5.48864 7.44185 5.50781 7.35662 5.54616C7.27282 5.58452 7.20676 5.63778 7.15847 5.70597C7.11017 5.77415 7.08532 5.85227 7.0839 5.94034H5.95037C5.95179 5.6733 6.02495 5.4375 6.16983 5.23295C6.31614 5.02841 6.51642 4.8679 6.77069 4.75142C7.02495 4.63494 7.31685 4.5767 7.6464 4.5767C7.96884 4.5767 8.25364 4.63139 8.5008 4.74077C8.74938 4.85014 8.94327 5.00071 9.08248 5.19247C9.2231 5.38281 9.2927 5.60085 9.29128 5.84659C9.29412 6.09091 9.21032 6.29119 9.03986 6.44744C8.87083 6.60369 8.65634 6.69602 8.3964 6.72443V6.75852C8.74867 6.79687 9.01358 6.90483 9.19114 7.08239C9.3687 7.25852 9.45605 7.48011 9.45321 7.74716C9.45463 8.00284 9.37793 8.2294 9.2231 8.42685C9.06969 8.62429 8.85591 8.77912 8.58176 8.89134C8.30904 9.00355 7.99441 9.05966 7.63787 9.05966Z"
          fill="black"
        />
        <path
          d="M8.57937 12.6364V17H7.39471V13.7273H7.36914L6.4146 14.2983V13.2926L7.48846 12.6364H8.57937Z"
          fill="black"
        />
        <path
          d="M7.72789 25.0597C7.47363 25.0597 7.23216 25.0199 7.00346 24.9403C6.77619 24.8594 6.57449 24.7322 6.39835 24.5589C6.22221 24.3857 6.08372 24.1598 5.98287 23.8814C5.88343 23.603 5.83443 23.2656 5.83585 22.8693C5.83727 22.5156 5.88272 22.1974 5.97221 21.9148C6.06312 21.6321 6.19167 21.3913 6.35787 21.1925C6.52548 20.9936 6.72576 20.8416 6.95872 20.7365C7.19167 20.63 7.45091 20.5767 7.73642 20.5767C8.05744 20.5767 8.34011 20.6385 8.58443 20.7621C8.82875 20.8857 9.02335 21.0511 9.16824 21.2585C9.31454 21.4645 9.39977 21.6918 9.42392 21.9403H8.2563C8.22931 21.8168 8.16681 21.7266 8.0688 21.6697C7.97221 21.6115 7.86142 21.5824 7.73642 21.5824C7.48784 21.5824 7.30531 21.6903 7.18883 21.9062C7.07377 22.1222 7.01483 22.4062 7.01199 22.7585H7.03755C7.09295 22.625 7.17676 22.5107 7.28897 22.4155C7.40119 22.3203 7.53187 22.2472 7.68102 22.196C7.83017 22.1449 7.98784 22.1193 8.15403 22.1193C8.41824 22.1193 8.64906 22.179 8.8465 22.2983C9.04537 22.4176 9.2002 22.581 9.31099 22.7884C9.42179 22.9957 9.47647 23.233 9.47505 23.5C9.47647 23.8125 9.40261 24.0859 9.25346 24.3203C9.10574 24.5547 8.90048 24.7365 8.6377 24.8658C8.37491 24.995 8.07164 25.0597 7.72789 25.0597ZM7.71937 24.1648C7.83727 24.1648 7.94167 24.1378 8.03258 24.0838C8.12491 24.0298 8.19664 23.956 8.24778 23.8622C8.30034 23.7685 8.32591 23.6619 8.32449 23.5426C8.32591 23.4219 8.30034 23.3153 8.24778 23.223C8.19664 23.1293 8.12491 23.0554 8.03258 23.0014C7.94167 22.9474 7.83727 22.9205 7.71937 22.9205C7.63272 22.9205 7.55247 22.9361 7.4786 22.9673C7.40616 22.9972 7.34295 23.0405 7.28897 23.0973C7.235 23.1527 7.19238 23.2187 7.16113 23.2955C7.1313 23.3707 7.11568 23.4531 7.11426 23.5426C7.11568 23.6619 7.14267 23.7685 7.19522 23.8622C7.24778 23.956 7.31951 24.0298 7.41042 24.0838C7.50133 24.1378 7.60431 24.1648 7.71937 24.1648Z"
          fill="black"
        />
        <path
          d="M22.0183 17V16.1477L23.6461 14.8011C23.7541 14.7116 23.8464 14.6278 23.9231 14.5497C24.0012 14.4702 24.0609 14.3885 24.1021 14.3047C24.1447 14.2209 24.166 14.1278 24.166 14.0256C24.166 13.9134 24.1419 13.8175 24.0936 13.7379C24.0467 13.6584 23.9814 13.5973 23.8975 13.5547C23.8137 13.5107 23.7172 13.4886 23.6078 13.4886C23.4984 13.4886 23.4018 13.5107 23.318 13.5547C23.2356 13.5987 23.1717 13.6634 23.1262 13.7486C23.0808 13.8338 23.0581 13.9375 23.0581 14.0597H21.9331C21.9331 13.7528 22.002 13.4886 22.1397 13.267C22.2775 13.0455 22.4721 12.875 22.7235 12.7557C22.975 12.6364 23.2697 12.5767 23.6078 12.5767C23.9572 12.5767 24.2598 12.6328 24.5154 12.745C24.7725 12.8558 24.9707 13.0121 25.1099 13.2138C25.2505 13.4155 25.3208 13.652 25.3208 13.9233C25.3208 14.0909 25.286 14.2578 25.2164 14.424C25.1468 14.5888 25.0218 14.7713 24.8414 14.9716C24.661 15.1719 24.4047 15.4105 24.0723 15.6875L23.6632 16.0284V16.054H25.3677V17H22.0183Z"
          fill="black"
        />
        <path
          d="M23.6379 25.0597C23.2941 25.0597 22.9887 25.0007 22.7217 24.8828C22.4561 24.7635 22.2472 24.5994 22.0953 24.3906C21.9433 24.1818 21.8666 23.9418 21.8651 23.6705H23.0583C23.0597 23.7543 23.0853 23.8295 23.135 23.8963C23.1862 23.9616 23.2558 24.0128 23.3438 24.0497C23.4319 24.0866 23.5328 24.1051 23.6464 24.1051C23.7558 24.1051 23.8524 24.0859 23.9362 24.0476C24.02 24.0078 24.0853 23.9531 24.1322 23.8835C24.1791 23.8139 24.2018 23.7344 24.2004 23.6449C24.2018 23.5568 24.1748 23.4787 24.1194 23.4105C24.0654 23.3423 23.9894 23.2891 23.8914 23.2507C23.7934 23.2124 23.6805 23.1932 23.5526 23.1932H23.1095V22.375H23.5526C23.6705 22.375 23.7742 22.3558 23.8637 22.3175C23.9546 22.2791 24.0249 22.2259 24.0747 22.1577C24.1258 22.0895 24.1507 22.0114 24.1492 21.9233C24.1507 21.8381 24.1301 21.7628 24.0874 21.6974C24.0448 21.6321 23.9852 21.581 23.9085 21.544C23.8332 21.5071 23.7458 21.4886 23.6464 21.4886C23.5384 21.4886 23.4419 21.5078 23.3566 21.5462C23.2728 21.5845 23.2068 21.6378 23.1585 21.706C23.1102 21.7741 23.0853 21.8523 23.0839 21.9403H21.9504C21.9518 21.6733 22.0249 21.4375 22.1698 21.233C22.3161 21.0284 22.5164 20.8679 22.7707 20.7514C23.0249 20.6349 23.3169 20.5767 23.6464 20.5767C23.9688 20.5767 24.2536 20.6314 24.5008 20.7408C24.7494 20.8501 24.9433 21.0007 25.0825 21.1925C25.2231 21.3828 25.2927 21.6009 25.2913 21.8466C25.2941 22.0909 25.2103 22.2912 25.0399 22.4474C24.8708 22.6037 24.6563 22.696 24.3964 22.7244V22.7585C24.7487 22.7969 25.0136 22.9048 25.1911 23.0824C25.3687 23.2585 25.4561 23.4801 25.4532 23.7472C25.4546 24.0028 25.3779 24.2294 25.2231 24.4268C25.0697 24.6243 24.8559 24.7791 24.5818 24.8913C24.309 25.0036 23.9944 25.0597 23.6379 25.0597Z"
          fill="black"
        />
        <path
          d="M15.3257 8.09091L15.3939 7.34091L14.7718 7.77557L14.4308 7.17898L15.1127 6.86364L14.4308 6.5483L14.7718 5.9517L15.3939 6.38636L15.3257 5.63636H16.0075L15.9394 6.38636L16.5615 5.9517L16.9024 6.5483L16.2206 6.86364L16.9024 7.17898L16.5615 7.77557L15.9394 7.34091L16.0075 8.09091H15.3257Z"
          fill="black"
        />
        <path
          d="M15.1902 16.804V13.6506H16.1447V16.804H15.1902ZM14.0907 15.7045V14.75H17.2441V15.7045H14.0907Z"
          fill="black"
        />
        <path
          d="M16.8691 20.4318L15.4629 25.6562H14.4657L15.872 20.4318H16.8691Z"
          fill="black"
        />
        <path
          d="M24.5794 4.63636V9H23.3947V5.72727H23.3691L22.4146 6.2983V5.29261L23.4885 4.63636H24.5794Z"
          fill="black"
        />
        <defs>
          <filter
            id="filter0_d_5102_2930"
            x="2.66797"
            y="2"
            width="28"
            height="28"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0823529 0 0 0 0 0.0470588 0 0 0 0 0.0352941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5102_2930"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5102_2930"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  }
}
