import { ReactNode } from "react";
import classnames from "classnames";
import { KeyValue } from "../../lib/keyboard";
import { CharStatus } from "../../lib/statuses";
import { Level } from "../../utils/isValidLevel";

type Props = {
  children?: ReactNode;
  value: KeyValue;
  width?: number;
  status?: CharStatus;
  onClick: (value: KeyValue) => void;
  level?: Level;
};

export const Key = ({
  children,
  status,
  width = 40,
  value,
  onClick,
  level,
}: Props) => {
  const statusKey =
    level === "killer" ? (status === "correct" ? "correct" : null) : status;

  const classes = classnames(
    "flex items-center justify-center rounded mx-0.5 font-bold cursor-pointer select-none text-xl",
    {
      "bg-[#D4DDEB]  hover:bg-[#BBC8D6] active:bg-[#727F93] text-black":
        !statusKey,
      "bg-[#727F93] text-white absent": statusKey === "absent",
      "bg-green-500 hover:bg-green-600 active:bg-green-700 text-white correct":
        statusKey === "correct",
      "bg-[#E2B53F] hover:bg-yellow-600 active:bg-yellow-700 text-white present":
        statusKey === "present",
    }
  );

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value);
    event.currentTarget.blur();
  };

  return (
    <button
      style={{ width: `${width}px`, height: "50px" }}
      className={classes}
      onClick={handleClick}
    >
      {children || value}
    </button>
  );
};
