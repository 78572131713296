import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export default function SEO({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  return (
    <Helmet>
      <meta http-equiv="X-Frame-Options" content="SAMEORIGIN" />
      <meta
        http-equiv="Content-Security-Policy"
        content="frame-ancestors 'self'"
      />

      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content="Paste your game results above if sharing on Facebook."
      />
      <meta property="og:url" content={url} />

      <meta name="twitter:creator" content="Hey Good Game" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://mathler.com/twitter.jpg" />
    </Helmet>
  );
}
