import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { PRACTICE } from "../components/toggle";

export default function useGameMode<T>(
  initialDefaultState: T | (() => T),
  initialPracticeState?: T | (() => T)
): [T, (value: T | ((prevState: T) => T)) => void] {
  const location = useLocation();
  const isPractice = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(PRACTICE) === "true";
  }, [location.search]);

  const [defaultModeValue, setDefaultModeValue] =
    useState<T>(initialDefaultState);
  const [practiceModeValue, setPracticeModeValue] = useState<T>(
    initialPracticeState ?? initialDefaultState
  );

  const modeValue = isPractice ? practiceModeValue : defaultModeValue;
  const setModeValue = isPractice ? setPracticeModeValue : setDefaultModeValue;

  return [modeValue, setModeValue];
}
