import { useEffect } from "react";

export function useEffectAfterMigration(
  callback: () => void,
  dependencies: any[]
) {
  useEffect(() => {
    const completed = localStorage.getItem("transferCompleted");
    if (completed) {
      callback();
      return;
    }

    const interval = setInterval(() => {
      const completed = localStorage.getItem("transferCompleted");
      if (completed) {
        callback();
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
