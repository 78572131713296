type Props = {
  index: number;
  size: number;
  label: string;
  highlight: boolean;
};

export const Progress = ({ index, size, label, highlight }: Props) => {
  return (
    <div className="flex justify-left m-1">
      <div className="items-center justify-center w-2 font-bold">
        {index + 1}
      </div>
      <div className="rounded-full w-full ml-2">
        <div
          style={{ width: `${8 + size}%` }}
          className={`${highlight ? "bg-[#17A34A]" : "bg-[#D0D0D0]"} text-xs font-medium text-white text-end py-0.5 px-2 rounded-full`}
        >
          {label}
        </div>
      </div>
    </div>
  );
};
