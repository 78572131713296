import useDarkMode from "../../hooks/useDarkMode";
import "./index.css";

export default function Banner() {
  const isDarkMode = useDarkMode();

  return (
    <div
      className="banner"
      style={{ backgroundColor: isDarkMode ? "#2D2928" : "#150c09" }}
    >
      <div className="inner">
        <p>
          Love Mathler? Try{" "}
          <a target="_blank" rel="noreferrer" href="https://sumplete.com/">
            Sumplete
          </a>{" "}
          and{" "}
          <a target="_blank" rel="noreferrer" href="https://crosswordle.com/">
            Crosswordle
          </a>
          !
        </p>
      </div>
    </div>
  );
}
