import { redirect } from "react-router-dom";

export function loader() {
  return redirect("/");
}

export default function NoFound() {
  return (
    <div>
      <h1>404 - Not Found</h1>
    </div>
  );
}
