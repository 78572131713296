import { Level } from "../../utils/isValidLevel";

export default function PracticeButton({
  level,
  isPractice,
}: {
  level: Level;
  isPractice: boolean;
  isGameLost: boolean;
}) {
  if (!isPractice) return <></>;

  const handleClick = () => {
    localStorage.removeItem(`practiceLevel-${level}`);
    window.location.href = `${window.location.origin}/${level}/?practice=true`;
  };

  return (
    <button
      className="align-center w-full py-4 rounded-md border border-1 border-[#fff] bg-[#17A34A] text-white text-center font-sans text-lg font-extrabold mt-2"
      onClick={handleClick}
    >
      New Practice Game
    </button>
  );
}
