import { Level } from "../utils/isValidLevel";

const gameStateKey = "gameState";

type StoredGameState = {
  guesses: string[][];
  solution: string;
};

const getKey = (baseKey: string, level: Level, isPractice: boolean) =>
  isPractice ? `${baseKey}-${level}-practice` : `${baseKey}-${level}`;

export const saveGameStateToLocalStorage = (
  gameState: StoredGameState,
  level: Level,
  isPractice: boolean
) => {
  localStorage.setItem(
    getKey(gameStateKey, level, isPractice),
    JSON.stringify(gameState)
  );
};

export const loadGameStateFromLocalStorage = (
  level: Level,
  isPractice: boolean
) => {
  const state = localStorage.getItem(getKey(gameStateKey, level, isPractice));
  return state ? (JSON.parse(state) as StoredGameState) : null;
};

const gameStatKey = "gameStats";

export type GameStats = {
  winDistribution: number[];
  gamesFailed: number;
  currentStreak: number;
  bestStreak: number;
  totalGames: number;
  successRate: number;
};

export const saveStatsToLocalStorage = (
  gameStats: GameStats,
  level: Level,
  isPractice: boolean
) => {
  localStorage.setItem(
    getKey(gameStatKey, level, isPractice),
    JSON.stringify(gameStats)
  );
};

export const loadStatsFromLocalStorage = (
  level: Level,
  isPractice: boolean
) => {
  const stats = localStorage.getItem(getKey(gameStatKey, level, isPractice));
  return stats ? (JSON.parse(stats) as GameStats) : null;
};
