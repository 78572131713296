import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import "./index.css";

export const PRACTICE = "practice";

const Toggle = ({ checked }: { checked: boolean }) => {
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(checked ?? false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const handleToggle = () => {
    setIsChecked(!isChecked);
    const params = new URLSearchParams();
    params.set(PRACTICE, `${!isChecked}`);
    setSearchParams(params);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsChecked(searchParams.get(PRACTICE) === "true");
  }, [location.search]);

  return (
    <div
      className={`toggle ${isChecked ? "on" : "off"}`}
      onClick={handleToggle}
    >
      <div className="slider"></div>
    </div>
  );
};

export default Toggle;
