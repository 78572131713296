import { CompletedRow } from "./CompletedRow";
import { CurrentRow } from "./CurrentRow";
import { EmptyRow } from "./EmptyRow";
import { CONFIG } from "../../constants/config";
import { Level } from "../../utils/isValidLevel";

type Props = {
  guesses: string[][];
  currentGuess: string[];
  solution: string;
  level: Level;
};

export const Grid = ({ guesses, currentGuess, solution, level }: Props) => {
  const empties =
    guesses.length < CONFIG.tries - 1
      ? Array.from(Array(CONFIG.tries - 1 - guesses.length))
      : [];

  return (
    <div className="pb-2">
      {guesses.map((guess, i) => (
        <CompletedRow key={i} guess={guess} solution={solution} level={level} />
      ))}
      {guesses.length < CONFIG.tries && (
        <CurrentRow guess={currentGuess} solution={solution} />
      )}
      {empties.map((_, i) => (
        <EmptyRow key={i} solution={solution} />
      ))}
    </div>
  );
};
