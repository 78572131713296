import { BaseModal } from "./BaseModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const SettingsModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Settings" isOpen={isOpen} handleClose={handleClose}>
      <p
        className="text-sm bg-gray-400 p-4 w-90 rounded mb-4 cursor-pointer enablecolorblind"
        onClick={() => {
          localStorage.setItem("colorblindmode", "colorblind");
          document.body.classList.add("colorblind");
        }}
      >
        Enable color blind mode
      </p>
      <p
        className="text-sm bg-gray-400 p-4 w-90 rounded mb-4 cursor-pointer disablecolorblind"
        onClick={() => {
          localStorage.setItem("colorblindmode", "nocolorblind");
          document.body.classList.remove("colorblind");
        }}
      >
        Disable color blind mode
      </p>

      <p
        className="text-sm bg-gray-400 p-4 w-90 rounded mb-4 cursor-pointer enabledarkmode"
        onClick={() => {
          localStorage.setItem("darkmode", "darkmode");
          document.body.classList.add("darkmode");
        }}
      >
        Enable dark mode
      </p>
      <p
        className="text-sm bg-gray-400 p-4 w-90 rounded mb-4 cursor-pointer disabledarkmode"
        onClick={() => {
          localStorage.setItem("darkmode", "nodarkmode");
          document.body.classList.remove("darkmode");
        }}
      >
        Disable dark mode
      </p>

      <div className="w-90 mx-auto items-center feedback">
        <a href="/feedback.html" target="_blank">
          Have feedback?
        </a>
      </div>
    </BaseModal>
  );
};
