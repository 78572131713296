export default function XIcon({ onClick }: { onClick: () => void }) {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        className="stroke-[#9EA3AE] hover:stroke-[#000000] transition-colors duration-200"
      >
        <path
          d="M1.99902 1.99805L14.001 14"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M14.001 1.99805L1.99902 14"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
