import { KeyValue } from "../../lib/keyboard";
import { getStatuses } from "../../lib/statuses";
import { Key } from "./Key";
import { useEffect } from "react";
import { ORTHOGRAPHY } from "../../constants/orthography";
import { Level } from "../../utils/isValidLevel";

type Props = {
  onChar: (value: string) => void;
  onDelete: () => void;
  onEnter: () => void;
  guesses: string[][];
  solution: string;
  level: Level;
};

export const Keyboard = ({
  onChar,
  onDelete,
  onEnter,
  guesses,
  solution,
  level,
}: Props) => {
  const charStatuses = getStatuses(guesses, solution);

  const onClick = (value: KeyValue) => {
    if (value === "ENTER") {
      onEnter();
    } else if (value === "DELETE") {
      onDelete();
    } else {
      onChar(value);
    }
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        onEnter();
      } else if (e.code === "Backspace") {
        onDelete();
      }
      // Take away key event listener for now
      else {
        const key = e.key.toUpperCase();
        if (key.length === 1 && key >= "!" && key <= "9") {
          onChar(key);
        }
      }
    };
    window.addEventListener("keyup", listener);
    return () => {
      window.removeEventListener("keyup", listener);
    };
  }, [onEnter, onDelete, onChar]);

  return (
    <div className="mb-3">
      <div className="flex justify-center mb-1">
        {ORTHOGRAPHY.slice(0, Math.floor(ORTHOGRAPHY.length * 0.65)).map(
          (char) => (
            <Key
              value={char}
              onClick={onClick}
              status={charStatuses[char]}
              key={`keyboard-key-${char}`}
              level={level}
            />
          )
        )}
      </div>
      <div className="flex justify-center">
        <Key
          width={75.4}
          value="ENTER"
          onClick={onClick}
          key={`keyboard-key-enter`}
        >
          Enter
        </Key>
        {ORTHOGRAPHY.slice(
          Math.floor(ORTHOGRAPHY.length * 0.65),
          ORTHOGRAPHY.length
        )
          .filter((chart) =>
            level !== "hard" ? ["*", "/", "+", "-"].includes(chart) : true
          )
          .map((char) => (
            <Key
              value={char}
              onClick={onClick}
              status={charStatuses[char]}
              key={`keyboard-key-${char}`}
              level={level}
            />
          ))}
        <Key
          width={75.4}
          value="DELETE"
          onClick={onClick}
          key={`keyboard-key-delete`}
        >
          Delete
        </Key>
      </div>
    </div>
  );
};
