import useDarkMode from "../../hooks/useDarkMode";
import { CharStatus } from "../../lib/statuses";
import classnames from "classnames";
import { Level } from "../../utils/isValidLevel";

type Props = {
  value?: string;
  status?: CharStatus;
  level?: Level;
};

export const Cell = ({ value, status, level }: Props) => {
  const isDarkMode = useDarkMode();
  const cellStatus =
    status === "present" && level === "killer" ? "absent" : status;

  const getStatusClasses = () => {
    if (cellStatus === "absent") {
      return "bg-[#727F93] text-white border-[#727F93] absent";
    } else if (cellStatus === "correct") {
      return "bg-green-500 text-white border-green-500 correct";
    } else if (cellStatus === "present") {
      return "bg-[#E2B53F] text-white border-[#E2B53F] present";
    }
    return "";
  };

  const baseClasses =
    "text-xl w-14 h-10 border-solid border-2 flex items-center justify-center mx-0.5 font-bold rounded font-black";

  const dynamicClasses = {
    "bg-white": !cellStatus,
    "border-[#3E3F41]": isDarkMode && !cellStatus,
    "border-[#D4DDEB]": !isDarkMode && !cellStatus,
    "border-[#A99F9C]": value && !cellStatus && isDarkMode,
    "border-[#000000]": value && !cellStatus && !isDarkMode,
    "cell-animation": !!value,
  };

  const classes = classnames(baseClasses, getStatusClasses(), dynamicClasses);

  return <div className={classes}>{value}</div>;
};
