import { shareStatus } from "../../lib/share";
import { Level } from "../../utils/isValidLevel";

export default function ShareButton({
  guesses,
  isGameLost,
  solutionIndex,
  solution,
  handleShare,
  isPracticeGame,
  level,
}: {
  guesses: string[][];
  isGameLost: boolean;
  solutionIndex: number;
  solution: string;
  handleShare: () => void;
  isGameWon: boolean;
  isPracticeGame: boolean;
  level: Level;
}) {
  if (isPracticeGame) return <></>;

  return (
    <button
      type="button"
      className="align-center w-full py-4 rounded-md border border-1 border-[#fff] bg-[#17A34A] text-white text-center font-sans text-lg font-extrabold mt-2"
      onClick={() => {
        shareStatus(guesses, isGameLost, solutionIndex, solution, level);
        handleShare();
      }}
    >
      Share Results
    </button>
  );
}
