import { Level } from "../utils/isValidLevel";
import { CONFIG } from "./config";

export const WORDS: Record<Level, string[]> = {
  easy: [
    "90-15",
    "60/12",
    "102-8",
    "36/12",
    "85-32",
    "44+18",
    "72/12",
    "90-15",
    "60/12",
    "102-8",
    "36/12",
    "85-32",
    "44+18",
    "72/12",
    "18-14",
    "14*20",
    "69-15",
    "24/12",
    "34-29",
    "48/12",
    "12*50",
    "95-32",
    "48/12",
    "60/12",
    "86-17",
    "48/24",
    "28-14",
    "66/11",
    "41-29",
    "54-23",
    "94-38",
    "36/12",
    "88/11",
    "48-17",
    "60/12",
    "74+19",
    "78-41",
    "12*10",
    "48/12",
    "12+19",
    "39-13",
    "60/12",
    "84-27",
    "12*10",
    "96-30",
    "45+23",
    "36/12",
    "11*12",
    "98-43",
    "27+34",
    "47-23",
    "12+74",
    "82+13",
    "72-65",
    "44/11",
    "34-27",
    "12+78",
    "43-21",
    "39/13",
    "98-12",
    "12*14",
    "27-13",
    "12+18",
    "92-13",
    "45/15",
    "20-18",
    "60/12",
    "119-7",
    "84/12",
    "97-23",
    "37+13",
    "11*12",
    "84-13",
    "75-26",
    "72/12",
    "13-11",
    "92-37",
    "11*10",
    "46-20",
    "55+43",
    "48/12",
    "14*10",
    "76-32",
    "17+54",
    "95-13",
    "10+19",
    "89-12",
    "72/12",
    "106-9",
    "24/12",
    "72-46",
    "13-10",
    "43+12",
    "12*20",
    "11*10",
    "56-21",
    "11+23",
    "36/12",
    "11*11",
    "73-26",
    "13+59",
    "84/12",
    "56-23",
    "28/14",
    "12-10",
    "46-28",
    "43+37",
    "54-21",
    "60/12",
    "14*20",
    "86-24",
    "84/12",
    "45-24",
    "24/12",
    "102-6",
    "12+19",
    "73-26",
    "41+12",
    "94-37",
    "11*14",
    "45-24",
    "57+46",
    "60/12",
    "10*17",
    "92-34",
    "17+64",
    "102-4",
    "45-21",
    "24/12",
    "43-16",
    "21+43",
    "87-23",
    "24+48",
    "45-23",
    "86-31",
    "21+32",
    "95-42",
    "65+12",
    "11*20",
    "54+13",
    "45-22",
    "40/10",
    "47-23",
    "11*13",
    "76-25",
    "11+34",
    "13+75",
    "13*10",
    "28-13",
    "52/13",
    "45-32",
    "11*12",
    "56-47",
    "40/10",
    "28-17",
    "12*40",
    "78-12",
    "36-23",
    "45-18",
    "60/12",
    "72/12",
    "10*17",
    "46-13",
    "12+79",
    "140/4",
    "32-17",
    "84/14",
    "93-10",
    "37+28",
    "72+12",
    "94-18",
    "101-5",
    "28-13",
    "45-12",
    "90+28",
    "24/12",
    "34+28",
    "23-14",
    "13*40",
    "49-12",
    "84/12",
    "19-12",
    "10*20",
    "15-11",
    "34+17",
    "23+19",
    "60-17",
    "22-14",
    "12*10",
    "76-11",
    "42-19",
    "48/12",
    "79-23",
    "34+28",
    "54+38",
    "24/12",
    "23-11",
    "38-31",
    "54-19",
    "64+17",
    "19-13",
    "12*43",
    "59-35",
    "50/10",
    "60/12",
    "35-13",
    "48+38",
    "71-11",
    "19-10",
    "11*40",
    "52-13",
    "213-9",
    "11+16",
    "43-16",
    "32+98",
    "67-13",
    "10*13",
    "55+17",
    "34+28",
    "88-13",
    "14*10",
    "85-23",
    "24+28",
    "85-43",
    "70/10",
    "34-28",
    "72/12",
    "102-6",
    "21+33",
    "60/12",
    "10*11",
    "71-16",
    "23+79",
    "36/12",
    "28-13",
    "70/14",
    "18-10",
    "43-18",
    "41+77",
    "44-13",
    "96/12",
    "13*10",
    "94-13",
    "14-12",
    "67-13",
    "30/10",
    "101-9",
    "21+17",
    "34+28",
    "84/12",
    "19-17",
    "12*30",
    "56-25",
    "36/12",
    "83-21",
    "17+45",
    "65-13",
    "17+84",
    "81+26",
    "91-63",
    "60/10",
    "34+28",
    "84-24",
    "52+14",
    "45-12",
    "34+58",
    "24/12",
    "76-23",
    "27-13",
    "109+3",
    "13+15",
    "96-24",
    "34+58",
    "17+46",
  ],
  normal: [
    "56/4+9",
    "27*3-9",
    "72/4*3",
    "112-47",
    "34*2-9",
    "64/8-2",
    "4*9-13",
    "81/9+6",
    "60/5*9",
    "72/4+8",
    "24*3+6",
    "8*4-17",
    "18-2*5",
    "49/7+5",
    "40-2+9",
    "169-41",
    "28/7*6",
    "85/5-7",
    "72*2-9",
    "12*8-9",
    "45/9*3",
    "68/4*3",
    "96-8/2",
    "28-8/4",
    "195-87",
    "42/6*4",
    "162/27",
    "68/4+9",
    "96/8+7",
    "12*8-7",
    "81/9+2",
    "184-97",
    "144/12",
    "45/5+7",
    "24*3+9",
    "36/6*8",
    "9*4+27",
    "72/9+3",
    "9*5-13",
    "82/2+7",
    "145-62",
    "72/4*3",
    "16*3-9",
    "42+7*8",
    "89-6+7",
    "12/6+9",
    "9*7+85",
    "12/6*9",
    "86/2*3",
    "36/6*8",
    "12*4-9",
    "48/8+7",
    "36*2-9",
    "14/2*8",
    "72-9+3",
    "21*4+8",
    "72+9-3",
    "32+9-7",
    "45*2-8",
    "278-81",
    "64/8*3",
    "24*3-8",
    "94+2-9",
    "13*6-8",
    "27/9*6",
    "48/4-7",
    "22*8+3",
    "65/5*2",
    "195-68",
    "52*3-6",
    "36/6*3",
    "28+9-7",
    "81/9+3",
    "16*4+3",
    "67+4-2",
    "48+9-7",
    "36*2-4",
    "65/5+9",
    "34*3-8",
    "13*4-2",
    "35/7*8",
    "62/2*7",
    "52-4/2",
    "58-6+9",
    "64/8+3",
    "31+3*4",
    "9*2-11",
    "60/4+7",
    "16*4-9",
    "68/4*7",
    "129-33",
    "26*2+9",
    "64/4*9",
    "34+7-8",
    "11-4*2",
    "42/7+9",
    "31-8+6",
    "132-59",
    "21/3*9",
    "95/5-2",
    "34*4-8",
    "83-2+8",
    "96/6+5",
    "37+8*2",
    "26*3-7",
    "95/5+8",
    "28-3+7",
    "67+8-9",
    "72/9+8",
    "11*7+5",
    "39+4-8",
    "13*9+7",
    "35/5*6",
    "124-37",
    "41*4-8",
    "42/6*9",
    "18+6-3",
    "90/9+7",
    "21/7+9",
    "85/5*3",
    "88/4+1",
    "34*2+7",
    "2*9-13",
    "45-3*8",
    "84/7+9",
    "31-2+3",
    "56/7+5",
    "43*2-8",
    "3*4+91",
    "13+5*4",
    "16*7-9",
    "35/5+1",
    "34-9+7",
    "35/7*3",
    "132-19",
    "80/5*3",
    "32*9-7",
    "12*9+3",
    "14+2-9",
    "38*3-6",
    "80/5*3",
    "17*3-4",
    "90/9+7",
    "134-65",
    "120/10",
    "24/4+9",
    "28-9+7",
    "45/5-9",
    "82*2-7",
    "23-8+2",
    "84/7*5",
    "156-23",
    "90/5*2",
    "48/4+9",
    "14*8+4",
    "2*9-16",
    "12*9-5",
    "91/7+6",
    "30-1+8",
    "134-68",
    "2*8-10",
    "28/4+9",
    "13*9-7",
    "36/4*5",
    "168-21",
    "43*3+8",
    "75/5*8",
    "21+9-3",
    "39*3-8",
    "31*7+3",
    "81/9*2",
    "56/7+3",
    "183-35",
    "54/6*5",
    "3*9+59",
    "90/9+1",
    "12*9+5",
    "16*2+8",
    "70/7+3",
    "60/5*8",
    "23*5-7",
    "70/7*3",
    "84/3*4",
    "50-2+8",
    "80-2+6",
    "28-2*5",
    "84/4*7",
    "9*7-13",
    "19*6-4",
    "77/7+3",
    "28/7*3",
    "95/5-3",
    "88-7+3",
    "70/7+3",
    "8*3-13",
    "80/4+5",
    "13*5-8",
    "48-4+7",
    "8*4-11",
    "42*3-8",
    "70/7*3",
    "143/11",
    "92/4+3",
    "17*4+7",
    "3*4+67",
    "90/9+8",
    "3*6-12",
    "94/2+3",
    "187-21",
    "44/4*6",
    "13*4-5",
    "68-4/2",
    "98-7+3",
    "72/8+9",
    "44+7*3",
    "3*8-13",
    "80/4+3",
    "13*2-5",
    "24*2-9",
    "40/4*2",
    "194-34",
    "27*5+2",
    "85/5*2",
    "54+8-7",
    "26*4-7",
    "12*4+9",
    "90/9*6",
    "81/9*3",
    "51-8+3",
    "137-28",
    "36/3*4",
    "75/5-1",
    "26*3-9",
    "67-9+4",
    "44/2+9",
    "46+7*3",
    "23*4-8",
    "35/5+3",
    "26-2+9",
    "45+7-3",
    "81/9+6",
    "45*6+8",
    "33+2-9",
    "12*6+9",
    "25/5*3",
    "129-21",
    "47*3-6",
    "48/6*3",
    "34-3*7",
    "70/7+2",
    "36-4+8",
    "161-31",
    "63/7*3",
    "95/5-4",
    "60/2*8",
    "42/6*3",
    "11*5-8",
    "80/8+3",
    "21*4-8",
    "72/9*3",
    "63-8+6",
    "162-35",
    "48/3+9",
    "21*5-9",
    "21*5-8",
    "80/8-7",
    "2*9-13",
    "36/9+7",
    "90/5*3",
    "88/4+6",
    "13*7+8",
    "2*9-13",
    "19-5+3",
    "72/9+6",
  ],
  hard: [
    "(7-4)*92",
    "96/3+8*2",
    "112/4*25",
    "(48-2)/4",
    "185*4-17",
    "96/(2+4)",
    "456/12-5",
    "(7-4)*92",
    "96/3+8*2",
    "112/4*25",
    "(48-2)/4",
    "185*4-17",
    "96/(2+4)",
    "456/12-5",
    "(41+8)/7",
    "1053-972",
    "18/(9-6)",
    "94*17-86",
    "54/(6/2)",
    "82/4*6+9",
    "126/3*17",
    "56*4/2-9",
    "190-72/4",
    "(90-8)/2",
    "1638/234",
    "28-(4+9)",
    "84/(4-2)",
    "(9-2)*65",
    "24*(8-3)",
    "94-60/12",
    "(8-6)*47",
    "185-26/2",
    "292/4*13",
    "1425-896",
    "24/2-6/3",
    "65*3+196",
    "(14+7)*9",
    "64/16+95",
    "85*9-723",
    "(98-4)/2",
    "132/3-27",
    "37*6+7*8",
    "43*2-6*9",
    "90/5*3-8",
    "(97-9)/4",
    "24*3-9/3",
    "(87-9)/3",
    "33*13-93",
    "94*2-169",
    "1239-956",
    "84/(2+1)",
    "23+9*152",
    "(30-2)/4",
    "36/6+923",
    "4*42-112",
    "35*3-102",
    "43*6/2+8",
    "72*3-198",
    "(45-1)/4",
    "86+7-8+3",
    "312-84/2",
    "78+7*4-9",
    "12*45-97",
    "127*4-35",
    "72/(9-6)",
    "(9-6)*87",
    "24/4+2*9",
    "172/4*36",
    "(39-9)/6",
    "162*3-47",
    "(90-2)/4",
    "93/3+4*2",
    "96/(4*2)",
    "28-24/12",
    "(9-3)*38",
    "163-34/2",
    "390/3*17",
    "285-64/2",
    "45+3*6-8",
    "17*12-64",
    "113*2-87",
    "54/(9-7)",
    "(8+3)*57",
    "36/4+7*9",
    "96/3+7*6",
    "104/4*19",
    "66/3+7*4",
    "(9-3)*67",
    "66/3+2*6",
    "164/4*23",
    "(31-4)/3",
    "(9-4)*21",
    "408/12-9",
    "13*11-29",
    "(77-5)/3",
    "27*34-89",
    "18*9-124",
    "21/3*9+3",
    "117/3*14",
    "26*8/2-3",
    "188-96/4",
    "98-36/12",
    "(5+8)*26",
    "129-48/4",
    "23*7-116",
    "237*3-19",
    "(6+3)*47",
    "48/(8-2)",
    "23*14-34",
    "72/(9-1)",
    "24/2*8+6",
    "(9-2)*86",
    "36/3+4*7",
    "164/4*37",
    "(44-8)/4",
    "126*8-13",
    "72/(3+1)",
    "765/17-9",
    "(58-4)/2",
    "342*3-19",
    "28/(5+2)",
    "348/29-8",
    "(21+9)/6",
    "1026-763",
    "12/(9-5)",
    "54*23-13",
    "57/3+6*9",
    "128/4*13",
    "456/12-5",
    "17352/18",
    "48/(9/3)",
    "312/12-9",
    "(33+9)/6",
    "213*2-19",
    "28+3*8-7",
    "1873-104",
    "62/2+9*4",
    "(9-3)*74",
    "146*3-35",
    "84/(8-5)",
    "34*13-42",
    "152/4*13",
    "576/12-9",
    "(21+9)/5",
    "1039-842",
    "28/(8-6)",
    "31*12-97",
    "81*11-36",
    "4370/230",
    "32*(7-2)",
    "96/3+9*4",
    "192/4*34",
    "(37-5)/8",
    "126*3-19",
    "264/4*33",
    "(28-4)/3",
    "(6-2)*34",
    "360/12-7",
    "14*12-23",
    "(86-2)/3",
    "17*24-97",
    "99/3+8*4",
    "132/4*17",
    "(24-8)/4",
    "(8-2)*47",
    "306/17-6",
    "12*15-31",
    "(87-2)/5",
    "15*42-91",
    "243*6-39",
    "108/4*23",
    "32*11-84",
    "952/56-5",
    "(67+5)/9",
    "1621-533",
    "54/(3+6)",
    "283*8-13",
    "112+9-32",
    "285*3-14",
    "84*13-81",
    "162/2*37",
    "(49-7)/3",
    "139*6-11",
    "(97-7)/9",
    "576/12-9",
    "262*9-47",
    "300/12-9",
    "(41+9)/5",
    "1039-312",
    "72/(6-3)",
    "415*7-37",
    "212/4*37",
    "2245-356",
    "30/3+2*9",
    "420/12-7",
    "(34+8)/7",
    "2364-256",
    "330/10-7",
    "84-44/11",
    "3013-814",
    "33/3+9*4",
    "224/7*34",
    "(70-8)/2",
    "367*5-19",
    "475/19-3",
    "(61+5)/6",
    "2035-533",
    "48/(6-2)",
    "32*13-10",
    "72/3+4*8",
    "200/4*17",
    "672/12-9",
    "(32+8)/4",
    "2451-835",
    "16/(5-1)",
    "37*13-74",
    "48/(8+4)",
    "90/3*7+7",
    "38+1*4-9",
    "36/4*2+5",
    "(8-4)*43",
    "32/2+9*4",
    "188/4*17",
    "(25-3)/2",
    "(8-6)*37",
    "680/17-3",
    "12*14-56",
    "(78-3)/5",
    "26/2*3-5",
    "28*8-112",
    "24/3*4+7",
    "156/3*12",
    "23*4/2-7",
    "145-84/4",
    "91-36/12",
    "(2+9)*16",
    "167-36/4",
    "43*5-128",
    "347*4-89",
    "(2+4)*67",
    "48/(9-5)",
    "36*34-29",
    "72/(8-5)",
    "72/2*9+3",
    "(8-4)*16",
    "45/5+8*9",
    "180/4*17",
    "(36-8)/7",
    "169*4-17",
    "84/(8+4)",
    "372/12-5",
    "(34+6)/5",
    "1672-843",
    "25/(8-3)",
    "32*47-56",
    "34*(9-2)",
    "76-30/10",
    "(9-2)*25",
    "184-16/4",
    "354/3*53",
    "1375-158",
    "34/2+8/4",
    "768/12-9",
    "34*5-112",
    "68+9*3-4",
    "281*3-57",
    "84/(7-3)",
    "656/16-9",
    "(9-2)*34",
    "99/3+4*7",
    "128/4*13",
    "(56-4)/2",
    "181*3-19",
    "32/(8-4)",
    "616/11-8",
  ],
  killer: [
    "56/4+9",
    "27*3-9",
    "72/4*3",
    "112-47",
    "34*2-9",
    "64/8-2",
    "4*9-13",
    "81/9+6",
    "60/5*9",
    "72/4+8",
    "24*3+6",
    "8*4-17",
    "18-2*5",
    "49/7+5",
    "40-2+9",
    "169-41",
    "28/7*6",
    "85/5-7",
    "72*2-9",
    "12*8-9",
    "45/9*3",
    "68/4*3",
    "96-8/2",
    "28-8/4",
    "195-87",
    "42/6*4",
    "162/27",
    "68/4+9",
    "96/8+7",
    "12*8-7",
    "81/9+2",
    "184-97",
    "144/12",
    "45/5+7",
    "24*3+9",
    "36/6*8",
    "9*4+27",
    "72/9+3",
    "9*5-13",
    "82/2+7",
    "145-62",
    "72/4*3",
    "16*3-9",
    "42+7*8",
    "89-6+7",
    "12/6+9",
    "9*7+85",
    "12/6*9",
    "86/2*3",
    "36/6*8",
    "12*4-9",
    "48/8+7",
    "36*2-9",
    "14/2*8",
    "72-9+3",
    "21*4+8",
    "72+9-3",
    "32+9-7",
    "45*2-8",
    "278-81",
    "64/8*3",
    "24*3-8",
    "94+2-9",
    "13*6-8",
    "27/9*6",
    "48/4-7",
    "22*8+3",
    "65/5*2",
    "195-68",
    "52*3-6",
    "36/6*3",
    "28+9-7",
    "81/9+3",
    "16*4+3",
    "67+4-2",
    "48+9-7",
    "36*2-4",
    "65/5+9",
    "34*3-8",
    "13*4-2",
    "35/7*8",
    "62/2*7",
    "52-4/2",
    "58-6+9",
    "64/8+3",
    "31+3*4",
    "9*2-11",
    "60/4+7",
    "16*4-9",
    "68/4*7",
    "129-33",
    "26*2+9",
    "64/4*9",
    "34+7-8",
    "11-4*2",
    "42/7+9",
    "31-8+6",
    "132-59",
    "21/3*9",
    "95/5-2",
    "34*4-8",
    "83-2+8",
    "96/6+5",
    "37+8*2",
    "26*3-7",
    "95/5+8",
    "28-3+7",
    "67+8-9",
    "72/9+8",
    "11*7+5",
    "39+4-8",
    "13*9+7",
    "35/5*6",
    "124-37",
    "41*4-8",
    "42/6*9",
    "18+6-3",
    "90/9+7",
    "21/7+9",
    "85/5*3",
    "88/4+1",
    "34*2+7",
    "2*9-13",
    "45-3*8",
    "84/7+9",
    "31-2+3",
    "56/7+5",
    "43*2-8",
    "3*4+91",
    "13+5*4",
    "16*7-9",
    "35/5+1",
    "34-9+7",
    "35/7*3",
    "132-19",
    "80/5*3",
    "32*9-7",
    "12*9+3",
    "14+2-9",
    "38*3-6",
    "80/5*3",
    "17*3-4",
    "90/9+7",
    "134-65",
    "120/10",
    "24/4+9",
    "72/4*3",
    "112-47",
    "34*2-9",
    "64/8-2",
    "4*9-13",
    "81/9+6",
    "60/5*9",
    "72/4+8",
    "24*3+6",
    "8*4-17",
    "18-2*5",
    "49/7+5",
    "40-2+9",
    "169-41",
    "28/7*6",
    "85/5-7",
    "72*2-9",
    "12*8-9",
    "45/9*3",
    "68/4*3",
    "96-8/2",
    "28-8/4",
    "195-87",
    "42/6*4",
    "162/27",
    "68/4+9",
    "96/8+7",
    "12*8-7",
    "81/9+2",
    "184-97",
    "144/12",
    "45/5+7",
    "24*3+9",
    "36/6*8",
    "9*4+27",
    "72/9+3",
    "9*5-13",
    "82/2+7",
    "145-62",
    "72/4*3",
    "16*3-9",
    "42+7*8",
    "89-6+7",
    "12/6+9",
    "9*7+85",
    "12/6*9",
    "86/2*3",
    "36/6*8",
    "12*4-9",
    "48/8+7",
    "36*2-9",
    "14/2*8",
    "72-9+3",
    "21*4+8",
    "72+9-3",
    "32+9-7",
    "45*2-8",
    "278-81",
    "64/8*3",
    "24*3-8",
    "94+2-9",
    "13*6-8",
    "27/9*6",
    "48/4-7",
    "22*8+3",
    "65/5*2",
    "195-68",
    "52*3-6",
    "36/6*3",
    "28+9-7",
    "81/9+3",
    "16*4+3",
    "67+4-2",
    "48+9-7",
    "36*2-4",
    "65/5+9",
    "34*3-8",
    "13*4-2",
    "35/7*8",
    "62/2*7",
    "52-4/2",
    "58-6+9",
    "64/8+3",
    "31+3*4",
    "9*2-11",
    "60/4+7",
    "16*4-9",
    "68/4*7",
    "129-33",
    "26*2+9",
    "64/4*9",
    "34+7-8",
    "11-4*2",
    "42/7+9",
    "31-8+6",
    "132-59",
    "21/3*9",
    "95/5-2",
    "34*4-8",
    "83-2+8",
    "96/6+5",
    "37+8*2",
    "26*3-7",
    "95/5+8",
    "28-3+7",
    "67+8-9",
    "72/9+8",
    "11*7+5",
    "39+4-8",
    "13*9+7",
    "35/5*6",
    "124-37",
    "41*4-8",
    "42/6*9",
    "18+6-3",
    "90/9+7",
    "21/7+9",
    "85/5*3",
    "88/4+1",
    "34*2+7",
    "2*9-13",
    "45-3*8",
    "84/7+9",
    "31-2+3",
    "56/7+5",
    "43*2-8",
    "3*4+91",
    "13+5*4",
    "16*7-9",
    "35/5+1",
    "34-9+7",
    "35/7*3",
    "132-19",
    "80/5*3",
    "32*9-7",
    "12*9+3",
    "14+2-9",
    "38*3-6",
    "80/5*3",
    "17*3-4",
    "90/9+7",
    "134-65",
    "120/10",
    "24/4+9",
    "28-9+7",
    "45/5-9",
    "82*2-7",
    "23-8+2",
    "84/7*5",
    "156-23",
  ],
};

if (CONFIG.normalization) {
  const levels = Object.keys(WORDS) as Array<keyof typeof WORDS>;
  for (let i = 0; i < levels.length; i++) {
    WORDS[levels[i]].forEach(
      (val, t) => (WORDS[levels[i]][t] = val.normalize(CONFIG.normalization))
    );
  }
}

function shuffle(array: string[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

if (CONFIG.shuffle) {
  const levels = Object.keys(WORDS) as Array<keyof typeof WORDS>;
  for (var i = 0; i < levels.length; i++) {
    shuffle(WORDS[levels[i]]);
  }
}
