/* eslint no-eval: 0 */

import { WORDS } from "../constants/wordlist";
import checkNumbers from "../utils/checkNumbers";
import { Level } from "../utils/isValidLevel";
import sortWord from "../utils/sortWord";

export type WordOfDayResponse = {
  level: string;
  solution: string;
  solutionMath: number;
  solutionIndex: number;
  tomorrow: number;
};

export const isWordInWordList = (word: string, solutionMath: number) => {
  return solutionMath === eval(word);
};

export const isWinningWordExact = (word: string, solution: string) => {
  return solution === word;
};

function parseMathExpression(word: string, simplifymultdiv: boolean): (string | null)[] | null {
  let regexParens = /\(([^)]+)\)|([^(]+)/g;
  let parensMatches = word.match(regexParens);

  let wordWithoutParens;
  if (parensMatches == null) {
    wordWithoutParens = word;
  }
  else {
    let parsedParensValues = parensMatches.map((value) => {
      if (value.includes('(')) {
        return (eval(value.slice(1, -1)) || 0).toString(); 
      }
      return value;
    });
  
    wordWithoutParens = parsedParensValues.join('');
  }

  if (simplifymultdiv) {
    while (true) {
      let regexMultDiv = /(\d+\.?\d*[/*]\d+\.?\d*)/;
      let multDivMatches: RegExpMatchArray | null = wordWithoutParens.match(regexMultDiv);
      if (multDivMatches == null) {
        break;
      }

      wordWithoutParens = wordWithoutParens.replace(multDivMatches[1], (eval(multDivMatches[1]) || 0).toString());
    }
  }

  const regex = /(?:^|[-+*/])(-?\d+\.?\d*)/g;
  let thisMatches;
  const matches = [];
  while ((thisMatches = regex.exec(wordWithoutParens)) !== null) {
    matches.push(thisMatches[1]);
  }

  return matches.length ? matches : null;
}

export function checkExpressionWithSolution(word: string, solution: string): boolean {
  const regex = /^[-+/*()0-9]+$/;
  if (!word.match(regex) || !solution.match(regex)) {
    return false;
  }

  if (eval(word) !== eval(solution)) {
    return false;
  }

  for (let testtype = 0; testtype < 2; testtype++) {
    let parsedSolution = parseMathExpression(solution, testtype === 1);
    let parsedWord = parseMathExpression(word, testtype === 1);
  
    if (!parsedSolution?.length || !parsedWord?.length) {
      return false; 
    }
  
    for (const num of parsedSolution) {
      const index = parsedWord.indexOf(num);
  
      if (index === -1) {
        return false; 
      }
  
      parsedWord.splice(index, 1); 
    }
  }

  return sortWord(solution) === sortWord(word) && checkNumbers(solution) === checkNumbers(word);
}

export const getWordOfDay = (
  level: Level,
  from = Date.now()
): WordOfDayResponse => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date("February 2, 2022 00:00:00").valueOf();
  const msInDay = 86400000;
  const index = Math.floor((from - epochMs) / msInDay);
  const nextday = (index + 1) * msInDay + epochMs;
  const wordsLevel = (WORDS as any)[level];

  return {
    level,
    solution: wordsLevel[index % wordsLevel.length],
    solutionMath: eval(wordsLevel[index % wordsLevel.length]),
    solutionIndex: index,
    tomorrow: nextday,
  };
};

export const randomWordOfDay = (level: Level): WordOfDayResponse => {
  const randomNumber = Math.floor(Math.random() * 365 + 1);

  const randomDay = new Date()
    .setDate(new Date().getDate() - randomNumber)
    .valueOf();
  return getWordOfDay(level, randomDay);
};
